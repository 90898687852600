import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Location, LocationDeletedBy, LocationShape } from '../types/Location';
import { useSearchParams } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import * as timeago from 'timeago.js';

import LocationMap from './LocationMap';

interface FilterOption {
  value: string;
  label: string;
  count: number | undefined;
}

const filterByCityId = (locations: Location[], cityId: string): Location[] => {
  if (cityId != '') {
    return locations.filter((location: Location) => location.city_id === cityId);
  } else {
    return locations;
  }
};

const filterByCuisineId = (locations: Location[], cuisineId: string): Location[] => {
  if (cuisineId !== '') {
    return locations.filter((location: Location) => location.cuisine_id === cuisineId);
  } else {
    return locations;
  }
};

const filterByMichelinGuideRating = (
  locations: Location[],
  michelinGuideRating: string,
): Location[] => {
  if (michelinGuideRating !== '') {
    return locations.filter(
      (location: Location) => location.michelin_guide_2022_rating === michelinGuideRating,
    );
  } else {
    return locations;
  }
};

const getCityOptions = (locations: Location[]): FilterOption[] => {
  const locationsByCityId = groupBy(locations, (location) => location.city_id);

  return sortBy(
    Object.entries(locationsByCityId).map(([cityId, locations]) => ({
      value: cityId,
      label: locations[0].city_name,
      count: (locations as Location[]).length,
    })),
    ({ label }) => label,
  );
};

const getCuisineOptions = (locations: Location[]): FilterOption[] => {
  const locationsByCuisineId = groupBy(locations, (location) => location.cuisine_id);

  return sortBy(
    Object.entries(locationsByCuisineId).map(([cityId, locations]) => ({
      value: cityId,
      label: locations[0].cuisine_name,
      count: (locations as Location[]).length,
    })),
    ({ label }) => label,
  );
};

const MICHELIN_GUIDE_RATINGS = {
  none: 'No MICHELIN Guide rating',
  bib_gourmand: 'MICHELIN Bib Gourmand',
  green_star: 'MICHELIN Green Star',
  one_star: 'One MICHELIN Star',
  two_star: 'Two MICHELIN Stars',
  three_star: 'Three MICHELIN Stars',
};

const getMichelinGuideRatingOptions = (locations: Location[]): FilterOption[] => {
  const locationsByMichelinGuideRating = groupBy(
    locations,
    (location) => location.michelin_guide_2022_rating,
  );

  return sortBy(
    Object.entries(locationsByMichelinGuideRating).map(
      ([michelinGuideRating, locations]) => ({
        value: michelinGuideRating,
        label: MICHELIN_GUIDE_RATINGS[michelinGuideRating] || 'Unknown',
        count: (locations as Location[]).length,
      }),
    ),
    ({ value }) => Object.keys(MICHELIN_GUIDE_RATINGS).indexOf(value),
  );
};

const LocationsViewer = (props: {
  defaultLatitude: number;
  defaultLongitude: number;
  defaultZoom: number;
  locations: Location[];
  showCityFilter: boolean;
  showMichelinGuideFilter: boolean;
}) => {
  const {
    defaultLatitude,
    defaultLongitude,
    defaultZoom,
    locations,
    showCityFilter,
    showMichelinGuideFilter,
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const cityId = searchParams.get('city_id') || '';
  const cuisineId = searchParams.get('cuisine_id') || '';
  const michelinGuideRating = searchParams.get('michelin_guide_rating') || '';
  const selectedLocationId = searchParams.get('selected_location') || '';

  const [filteredLocations, setFilteredLocations] = useState<Location[]>(locations);
  const [activeFilteredLocationsCount, setActiveFilteredLocationsCount] =
    useState<number>(0);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);

  const cities = useMemo(() => getCityOptions(locations), [locations]);

  useEffect(() => {
    const filteredList = filterByMichelinGuideRating(
      filterByCuisineId(filterByCityId(locations, cityId), cuisineId),
      michelinGuideRating,
    );

    setFilteredLocations(filteredList);

    setActiveFilteredLocationsCount(
      filteredList.filter((location) => location.deleted_at === null).length,
    );
  }, [cityId, cuisineId, michelinGuideRating]);

  useEffect(() => {
    if (selectedLocationId !== '') {
      const location = locations.find((location) => location.id === selectedLocationId);
      setSelectedLocation(location);
    }
  }, [selectedLocationId]);

  const cuisines = useMemo(() => getCuisineOptions(locations), [locations]);
  const michelinGuideRatings = useMemo(
    () => getMichelinGuideRatingOptions(locations),
    [locations],
  );

  const listContainer = useRef(null);

  return (
    <>
      <section className="filters">
        {showCityFilter && (
          <div className="filter">
            <select
              name="city"
              value={cityId}
              onChange={(event) =>
                setSearchParams((params) => {
                  params.set('city_id', event.target.value);
                  return params;
                })
              }
            >
              <option value={''}>City</option>
              {cities.map((city) => (
                <option key={city.value} value={city.value}>
                  {city.label} ({city.count})
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="filter">
          <select
            name="cuisine"
            value={cuisineId}
            onChange={(event) =>
              setSearchParams((params) => {
                params.set('cuisine_id', event.target.value);
                return params;
              })
            }
          >
            <option value={''}>Cuisine</option>
            {cuisines.map((cuisine) => (
              <option key={cuisine.value} value={cuisine.value}>
                {cuisine.label} ({cuisine.count})
              </option>
            ))}
          </select>
        </div>

        <div className="filter">
          {showMichelinGuideFilter && (
            <select
              name="michelin_rating"
              value={michelinGuideRating || ''}
              onChange={(event) =>
                setSearchParams((params) => {
                  console.log(event.target.value);
                  if (event.target.value === 'null') {
                    params.delete('michelin_guide_rating');
                  } else {
                    params.set('michelin_guide_rating', event.target.value);
                  }
                  return params;
                })
              }
            >
              <option value={''}>Michelin Guide rating</option>
              {michelinGuideRatings.map((michelinGuideRating) => (
                <option key={michelinGuideRating.value} value={michelinGuideRating.value}>
                  {michelinGuideRating.label} ({michelinGuideRating.count})
                </option>
              ))}
            </select>
          )}
        </div>

        {/* <CSVLink data={csvData} headers={CSV_HEADERS}><button>Download CSV</button></CSVLink> */}
      </section>
      <LocationMap
        defaultLatitude={defaultLatitude}
        defaultLongitude={defaultLongitude}
        defaultZoom={defaultZoom}
        locations={filteredLocations.filter(
          (location) => location.latitude && location.longitude,
        )}
        onSelectLocation={(location) => {
          setSearchParams((params) => {
            params.set('selected_location', location.id);
            return params;
          });

          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        selectedLocation={selectedLocation}
      />
      <div className="list-container" ref={listContainer}>
        <div
          style={{ textTransform: 'uppercase', fontWeight: 'bold', paddingBottom: '1em' }}
        >
          {activeFilteredLocationsCount} active locations
        </div>

        {selectedLocation && (
          <div className="selected-location">
            <h3>
              {selectedLocation.deleted_at && <s>{selectedLocation.name}</s>}
              {!selectedLocation.deleted_at && <>{selectedLocation.name}</>}
            </h3>
            <p>
              📪 {selectedLocation.address_line_1}, {selectedLocation.postcode}
              {!selectedLocation.latitude && (
                <span
                  className="text-muted"
                  style={{ fontSize: '0.8em', marginTop: '0.3em', marginLeft: '0.3em' }}
                >
                  (Not shown on map)
                </span>
              )}
            </p>
            <p>🍴 {selectedLocation.cuisine_name}</p>
            {selectedLocation.michelin_guide_2022_rating && (
              <p>
                ⭐ {MICHELIN_GUIDE_RATINGS[selectedLocation.michelin_guide_2022_rating]}
              </p>
            )}
            {selectedLocation.website_url && (
              <p>
                🌐{' '}
                <a href={selectedLocation.website_url} target="_blank" rel="noreferrer">
                  {selectedLocation.website_url}
                </a>
              </p>
            )}
            {!selectedLocation.included_in_initial_import && (
              <p
                className="text-muted"
                style={{ fontSize: '0.8em', marginTop: '0.3em' }}
                title={selectedLocation.created_at.split('T')[0]}
              >
                Added {timeago.format(selectedLocation.created_at)}
              </p>
            )}
            {selectedLocation.deleted_at && (
              <p
                className="text-muted"
                style={{ fontSize: '0.8em', marginTop: '0.3em' }}
                title={selectedLocation.deleted_at.split('T')[0]}
              >
                Removed{' '}
                {selectedLocation.deleted_by === LocationDeletedBy.AmericanExpress
                  ? 'by American Express'
                  : 'manually by Platinum Dining'}{' '}
                {timeago.format(selectedLocation.deleted_at)}
              </p>
            )}
          </div>
        )}

        {filteredLocations.map((location) => (
          <div
            key={location.id}
            className="location"
            onClick={() => {
              setSearchParams((params) => {
                params.set('selected_location', location.id);
                return params;
              });

              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            style={{
              opacity: location.deleted_at ? 0.4 : 1,
            }}
          >
            <h3>
              {location.deleted_at && <s>{location.name}</s>}
              {!location.deleted_at && <>{location.name}</>}
              {location.is_new && <span className="tag tag-new">New</span>}
            </h3>
            <p className="text-muted">
              {location.address_line_1}, {location.postcode}
            </p>
            {!location.latitude && (
              <p className="text-muted" style={{ fontSize: '0.8em' }}>
                (Not shown on map)
              </p>
            )}
            <p>{location.cuisine_name}</p>
            {!location.included_in_initial_import && !location.deleted_at && (
              <p
                className="text-muted"
                style={{ fontSize: '0.8em' }}
                title={location.created_at.split('T')[0]}
              >
                Added {timeago.format(location.created_at)}
              </p>
            )}
            {location.deleted_at && (
              <p
                className="text-muted"
                style={{ fontSize: '0.8em' }}
                title={location.deleted_at.split('T')[0]}
              >
                Removed{' '}
                {location.deleted_by === LocationDeletedBy.AmericanExpress
                  ? 'by American Express'
                  : 'manually by Platinum Dining'}{' '}
                {timeago.format(location.deleted_at)}
              </p>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

LocationsViewer.propTypes = {
  defaultLatitude: PropTypes.number.isRequired,
  defaultLongitude: PropTypes.number.isRequired,
  defaultZoom: PropTypes.number.isRequired,
  locations: PropTypes.arrayOf(LocationShape).isRequired,
  showCityFilter: PropTypes.bool.isRequired,
  showMichelinGuideFilter: PropTypes.bool.isRequired,
};

export default LocationsViewer;
